<template>
  <div>
    <b-table
      striped
      hover
      :items="userList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(msisdn)="{ item }">
        {{ item.msisdn || "-" }}
      </template>

      <template v-slot:cell(name)="{ item }">
        {{ item.name || "-" }}
      </template>

      <template v-slot:cell(email)="{ item }">
        {{ item.email || "-" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/edit-users/' + item.id">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.id)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.perPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.page - 1) * 10 + 1 }} to
        {{ (resultData.page - 1) * 10 + rows }} from
        {{ resultData.total }}
      </small>
      <b-pagination
        class="justifvy-content-end"
        v-model="currentPageList"
        :total-rows="resultData.total"
        :per-page="resultData.perPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    userData(value) {
      this.userList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      userList: [],
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "email" },
        { key: "msisdn", label: "No Hp" },
        { key: "actions" },
      ],
    };
  },
  computed: {
    rows() {
      return this.userList.length;
    },
  },
  created() {
    console.log("first");
  },
};
</script>

<style></style>
